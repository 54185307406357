import * as React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql, Link } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query MinistryQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            id
            fields {
              slug
            }
            parent {
              id
              ... on File {
                name
                sourceInstanceName
              }
            }
            frontmatter {
              title
              category
              description
            }
          }
        }
      }
    }
  `)

  const items = data.allMarkdownRemark.edges
  const ministries = items.filter(
    item => item.node.frontmatter.category === "ministry"
  )

  console.log(ministries)

  return (
    <Layout>
      <div class="overflow-x-hidden bg-gradient-to-b from-blue-700">
        <div class="px-6 py-8 ">
          <div class="container flex justify-between mx-auto">
            <div class="container overflow-x-hidden ">
              <h1 class="text-xl text-white text-center  p-4 justify-center text-5xl	">
                Ministries
              </h1>
              <div class="px-0 py-8">
                {ministries.map(ministry => {
                  return (
                    <div class="bg-white lg:mx-10 lg:flex  lg:shadow-lg lg:rounded-lg mt-3">
                      <div class="lg:w-1/2 items-center">
                        <div
                          class="h-64 bg-cover  lg:h-full"
                          style={{
                            backgroundImage: "url('/bible.jpg')",
                          }}
                        />
                      </div>
                      <div class="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
                        <h2 class="text-3xl text-red-800 font-bold">
                          {ministry.node.frontmatter.title}
                        </h2>
                        <p class="mt-4 text-gray-600">
                          {ministry.node.frontmatter.description}
                        </p>
                        <div class="mt-8">
                          <Link
                            class="bg-red-900 text-gray-100 px-5 py-3 font-semibold rounded"
                            to={`${ministry.node.fields.slug}`}
                          >
                            More
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
